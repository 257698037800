
@font-face {
  font-family: 'Aqua';
  src: local('Aqua'),
       url('./fonts/AquaGrotesque.woff') format('woff'),
       url('./fonts/AquaGrotesque.woff2') format('woff2');
}

.App {
  position: relative;
  text-align: center;

  background-image: linear-gradient(to right top, #060e37, #060f3a, #06103d, #061141, #071244, #081349, #08154d, #091652, #091859, #0a1a60, #0b1b67, #0c1d6e);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-footer {
  position: fixed;
  bottom: 0;
  width: 100vw;
  color: #1d2b69;

  margin-bottom: 1rem;
}

.App-footer > a {
  color: #1d2b69;
}

h1 {
  font-size: 55px;
}

a {
  color: inherit;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:active {
  text-decoration: underline;
}


.title {
  font-family: Aqua;
  font-weight: normal;
  font-style: normal; 
}

.description {
  font-size: 24px;
  color: lightgray;
  width: 40%;
  margin-bottom: 3rem;
}

@media only screen and (max-width: 600px) {
  .title {
    font-size: 34px;
  }

  .description {
    font-size: 16px;
    width: 80%;
  }

  .contact-list-item {
    max-width: 80%;
    margin: auto;
  }
}

.icons-container {
  display: flex;
  flex-direction: row;
}

.icons-container > a:not(:last-child) {
  margin-right: 2rem;
}

.icons-container > a, a:visited {
  color: gray;
}

.icons-container > a:hover {
  color: lightgray;
}

.contact-list {
  display: flex;
  flex-direction: column;

  color: lightgray;

}

.contact-list-item {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

.contact-list-item-key {
  margin-right: 2rem;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
